import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'hidden',
  '& .DashboardLayout-drawer .MuiDrawer-paper': {
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      borderRadius: '10px',
      border: '2px solid rgba(0,0,0,.1)',
    },
  },
  '& .DashboardLayout-bodyContainer': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
}
