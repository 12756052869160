import React, { createContext, SetStateAction, useContext } from 'react'

export type DashboardLayoutContextData = {
  setLayoutTitle: React.Dispatch<
    SetStateAction<string | { title: string; subtitle?: string } | undefined>
  >
  setLayoutLoading: React.Dispatch<SetStateAction<boolean>>
}

const DashboardLayoutContext = createContext<DashboardLayoutContextData | null>(null)

export function useDashboardLayout(): DashboardLayoutContextData {
  return useContext(DashboardLayoutContext)!
}

export default DashboardLayoutContext
