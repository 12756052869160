import { nav } from '@local/ui'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DesktopMacIcon from '@mui/icons-material/DesktopMac'
import DvrIcon from '@mui/icons-material/Dvr'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import GestureIcon from '@mui/icons-material/Gesture'
import LanguageIcon from '@mui/icons-material/Language'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SettingsIcon from '@mui/icons-material/Settings'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import TimelineIcon from '@mui/icons-material/Timeline'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import WidgetsIcon from '@mui/icons-material/Widgets'

export const sideMenuManager: nav.MenuManager = nav.configureSideMenus([
  {
    type: 'menu',
    icon: <DashboardIcon />,
    title: '대시보드',
    href: '/',
  },
  {
    type: 'menu',
    icon: <DesktopMacIcon />,
    title: '브라우저 접속 통계',
    href: '/browser-access-stats',
  },
  {
    type: 'menu',
    icon: <ViewInArIcon />,
    title: '코딩팩 접속 통계',
    href: '/codingpack-access-stats',
  },

  {
    type: 'menu',
    icon: <MailOutlineIcon />,
    title: '제휴 문의',
    href: '/alliance-inq',
  },
  {
    type: 'menu',
    icon: <SupervisorAccountIcon />,
    title: '제휴 업체 관리',
    href: '/company/list',
  },
  {
    type: 'menu',
    icon: <DashboardIcon />,
    title: '팝업 목록 관리',
    href: '/popup',
  },
  {
    type: 'menu',
    icon: <PictureAsPdfIcon />,
    title: '교육 자료 관리',
    href: '/edu-resource',
  },

  {
    type: 'section',
    icon: <WidgetsIcon />,
    title: '하드웨어 및 드라이버',
    submenus: [
      {
        type: 'menu',
        title: '하드웨어 목록',
        href: '/edu-toy/list',
      },
      {
        type: 'menu',
        title: '드라이버 자료실',
        href: '/edu-toy/drivers',
      },
    ],
  },
  {
    type: 'section',
    icon: <WidgetsIcon />,
    title: '블록 메이커',
    submenus: [
      {
        type: 'menu',
        title: '블록 메이커 목록',
        href: '/simul/list',
      },
    ],
  },
  {
    type: 'menu',
    icon: <EmojiEventsIcon />,
    title: '이벤트 관리',
    href: '/event',
  },
  {
    type: 'menu',
    icon: <GestureIcon />,
    title: '퀵드로우 관리',
    href: '/quick-draw',
  },
  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '데이터 과학',
    submenus: [
      {
        type: 'menu',
        title: '테이블 데이터 관리',
        href: '/data-science/grid',
      },
    ],
  },

  {
    type: 'section',
    icon: <AccountCircleIcon />,
    title: '사용자 관리',
    submenus: [
      {
        type: 'menu',
        title: '사용자 목록',
        href: '/user',
      },
      {
        type: 'menu',
        title: '차단 사용자',
        href: '/user-kicks',
      },
      {
        type: 'menu',
        title: '탈퇴 사용자',
        href: '/user-withdraws',
      },
    ],
  },

  {
    type: 'section',
    icon: <DvrIcon />,
    title: '게시판 관리',
    submenus: [
      {
        type: 'menu',
        title: '자주하는 질문',
        href: '/faq',
      },
      {
        type: 'menu',
        title: '공지사항',
        href: '/bbs/notice',
      },
      {
        type: 'menu',
        title: '묻고 답하기',
        href: '/bbs/qna',
      },
      {
        type: 'menu',
        title: '우리들 이야기',
        href: '/bbs/talk',
      },
      {
        type: 'menu',
        title: '제안하기',
        href: '/bbs/suggestion',
      },
      {
        type: 'menu',
        title: '갤러리',
        href: '/gallery',
      },
      {
        type: 'menu',
        title: '자료실',
        href: '/pds',
      },
    ],
  },

  {
    type: 'section',
    icon: <WidgetsIcon />,
    title: '시스템 블록 코딩',
    submenus: [
      {
        type: 'menu',
        title: '카테고리 스타일',
        href: '/block/category-settings',
      },
      {
        type: 'menu',
        title: '워크 스페이스 설정',
        href: '/blk-workspace-settings',
        match: (path) => {
          return (
            path.startsWith('/blk-workspace-settings') || path.startsWith('/blk-workspace-detail')
          )
        },
      },
    ],
  },
  {
    type: 'section',
    icon: <StarRoundedIcon />,
    title: '오브젝트 관리',
    submenus: [
      {
        type: 'menu',
        title: '오브젝트 이미지 관리',
        href: '/object-images',
      },
      {
        type: 'menu',
        title: '배경 이미지 관리',
        href: '/object-bg-images',
      },
      {
        type: 'menu',
        title: '사운드 관리',
        href: '/object-sounds',
      },
    ],
  },

  {
    type: 'section',
    icon: <AssignmentIcon />,
    title: '로그 관리',
    submenus: [
      {
        type: 'menu',
        title: '접속 로그',
        href: '/access-log',
      },
      {
        type: 'menu',
        title: 'Job 로그',
        href: '/jobs',
      },
      {
        type: 'menu',
        title: '시스템 로그',
        href: '/app-log',
      },
      {
        type: 'menu',
        title: '파트너 접속 로그',
        href: '/partner-access-logs',
      },
    ],
  },
  {
    type: 'section',
    icon: <SettingsIcon />,
    title: '시스템 관리',
    submenus: [
      {
        type: 'menu',
        title: '사용자ID 일괄생성',
        href: '/user-create-request',
      },
      {
        type: 'menu',
        title: '어드민 관리',
        href: '/admin-control',
      },
      {
        type: 'menu',
        title: '약관 관리',
        href: '/clause',
      },
      {
        type: 'menu',
        title: '서버 정보',
        href: '/server',
      },
      {
        type: 'menu',
        title: '캐시 관리',
        href: '/cache',
      },
      {
        type: 'menu',
        title: '본인 인증 조회',
        href: '/kmcis',
      },
    ],
  },
  {
    type: 'menu',
    icon: <LanguageIcon />,
    title: '다국어 관리',
    href: '/i18n',
  },
  {
    type: 'menu',
    icon: <PowerSettingsNewIcon />,
    title: '로그아웃',
    href: '/logout',
  },
])
