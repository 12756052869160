import useStore from '@/store/useStore'
import { useMediaQuery, useTheme } from '@mui/material'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'

/**
 * Large 화면에서 왼쪽 메뉴가 기본으로 열리도록 체크하는 컴포넌트
 */
function SidebarAutoOpenCheck() {
  const { sidebarStore } = useStore()
  const theme = useTheme()
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'))
  const isAutoOpenChecked = sidebarStore.isAutoOpenFinish
  const isSidebarOpen = sidebarStore.isOpen
  const [prepared, setPrepared] = useState(false)

  useEffect(() => {
    setPrepared(true)
  }, [])

  useEffect(() => {
    if (!prepared) return
    if (isAutoOpenChecked) return

    if (!smOrDown && !isSidebarOpen) {
      sidebarStore.setOpen(true)
    }
    sidebarStore.setAutoOpenFinish(true)
  }, [prepared, smOrDown, isAutoOpenChecked, sidebarStore, isSidebarOpen])

  return null
}

export default observer(SidebarAutoOpenCheck)
