import { mainLayoutConstants } from '@/layout-constants'
import { flatSx } from '@local/ui'
import { Icon, ListItemButton, ListItemIcon, SxProps, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import clsx from 'clsx'
import * as React from 'react'

const { sidemenu } = mainLayoutConstants

type Props = {
  sx?: SxProps
  className?: string
  icon?: React.ReactNode
  title: string
  active: boolean
  onClick: (event: React.MouseEvent) => void
}

export default function SideMenuItem(props: Props) {
  const { sx, className, icon, title, active, onClick } = props

  return (
    <ListItemButton
      onClick={onClick}
      className={clsx('SideMenuItem-root', className, {
        'Mui-active': active,
      })}
      sx={flatSx(
        {
          '&:hover': {
            backgroundColor: sidemenu.bgColorHoverBack,
          },

          // 제목 부분
          '& .SideMenuItem-title': {
            fontWeight: 400,
            fontSize: sidemenu.fontSize,
            color: sidemenu.fgColor,
          },

          // 아이콘 부분
          '& .MuiListItemIcon-root': {
            color: alpha(sidemenu.fgColor, 0.7),
            width: sidemenu.iconWidth,
            minWidth: sidemenu.iconWidth,
          },

          '&.Mui-active': {
            color: sidemenu.fgColorActive,
            backgroundColor: sidemenu.bgColorBack,
            '&:hover': {
              backgroundColor: sidemenu.bgColorHoverBack,
            },

            // 제목 부분
            '& .SideMenuItem-title': {
              color: sidemenu.fgColorActive,
            },

            // 아이콘 부분
            '& .MuiListItemIcon-root': {
              color: sidemenu.fgColorActive,
            },
          },
        },
        sx,
      )}
    >
      <>
        {icon && (
          <ListItemIcon>{typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}</ListItemIcon>
        )}
        <Typography variant="subtitle1" component="div" className="SideMenuItem-title">
          {title}
        </Typography>
      </>
    </ListItemButton>
  )
}
