import { mainLayoutConstants } from '@/layout-constants'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
  Box,
  IconButton,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  SxProps,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useMemo } from 'react'

const { sidemenu } = mainLayoutConstants

interface AppBarProps extends MuiAppBarProps {
  shift: boolean
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'shift',
})<AppBarProps>(({ theme, shift }) => ({
  background: '#FFF',
  zIndex: theme.zIndex.drawer + (shift ? 1 : -1),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  color: '#000',
  ...(shift && {
    width: `calc(100% - ${sidemenu.width}px)`,
    marginLeft: sidemenu.width,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!shift && {
    '& .MuiToolbar-root': {
      paddingLeft: theme.spacing(3.5),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(3),
      },
    },
  }),
}))

type Props = {
  sx?: SxProps
  className?: string
  layoutTitle?: string | { title: string; subtitle?: string }
  isSidebarOpen: boolean
  onClickMenuButton: () => void
}

function getTitle(layoutTitle: Props['layoutTitle']): { title?: string; subtitle?: string } {
  if (typeof layoutTitle === 'undefined') return {}
  if (typeof layoutTitle === 'string') {
    return { title: layoutTitle }
  }
  return layoutTitle
}

/**
 * 대시보드 상단 Topbar
 */
export default function Topbar(props: Props) {
  const { sx, className, isSidebarOpen, onClickMenuButton } = props
  const theme = useTheme()
  const { title, subtitle } = useMemo(() => getTitle(props.layoutTitle), [props.layoutTitle])
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'))
  const shift = !!(isSidebarOpen && !smOrDown)

  return (
    <AppBar
      className={clsx('Topbar-root', className)}
      position="fixed"
      shift={shift}
      elevation={1}
      sx={sx}
    >
      <Toolbar className="Topbar-toolbar">
        <IconButton onClick={onClickMenuButton} className="Topbar-menuButton" size="large">
          {isSidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <Box sx={{ ml: 1 }}>
          {title && (
            <Typography className="Topbar-title" variant="h5" component="div">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography className="Topbar-subtitle" variant="body1" component="div">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}
