import CustomBreadcrumbs, { CustomBreadcrumbsProps } from '@/components/CustomBreadcrumbs'
import EnsureLogin from '@/components/EnsureLogin'
import PwMustResetChecker from '@/components/PwMustResetChecker'
import { mainLayoutConstants } from '@/layout-constants'
import useStore from '@/store/useStore'
import { flatSx, useElementOffset } from '@local/ui'
import { Box, Drawer, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react'
import React, { useMemo, useState } from 'react'
import { DrawerHeader } from '../DrawerHeader'
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import DashboardLayoutContext from './DashboardLayoutContext'
import SidebarAutoOpenCheck from './SidebarAutoOpenCheck'
import { rootSx } from './style'

const { sidemenu, content } = mainLayoutConstants
const { bgColor: CONTENT_BG_COLOR, defaultPaddingBottom: CONTENT_PADDING_BOTTOM } = content

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'contentShift',
})<{
  contentShift: number
}>(({ theme, contentShift }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: CONTENT_BG_COLOR,
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: contentShift,
  ...(contentShift !== 0 && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(contentShift === 0 && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

type Props = {
  title: string
  children?: React.ReactNode
  breadcrumbProps?: CustomBreadcrumbsProps
  contentPaddingBottom?: number | string
}

/**
 * 대시보드 레이아웃
 */
function DashboardLayout(props: Props) {
  const { children, breadcrumbProps, contentPaddingBottom = CONTENT_PADDING_BOTTOM } = props
  const [layoutTitle, setLayoutTitle] = useState<
    string | { title: string; subtitle: string } | undefined
  >(props.title)
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  // ref 대신 state로 한 것은 의도한 것
  // nextjs에서 페이지가 변경되면 body의 위치 체크를 다시하는 용도
  const [bodyElement, setBodyElement] = useState<HTMLElement | null>(null)

  const { sidebarStore } = useStore()
  const isSidebarOpen = sidebarStore.isOpen
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'))
  const contentShift = smOrDown ? 0 : isSidebarOpen ? sidemenu.width : 0

  // bodyElement의 좌측 상단의 위치
  const bodyPosition = useElementOffset(bodyElement, [contentShift])

  const contextData = useMemo(
    () => ({
      setLayoutTitle,
      setLayoutLoading: setLoading,
    }),
    [setLayoutTitle, setLoading],
  )

  return (
    <EnsureLogin>
      <Box className="DashboardLayout-root" sx={rootSx}>
        <PwMustResetChecker />
        <SidebarAutoOpenCheck />
        <DashboardLayoutContext.Provider value={contextData}>
          <Topbar
            layoutTitle={layoutTitle}
            isSidebarOpen={isSidebarOpen}
            onClickMenuButton={() => sidebarStore.toggleOpen()}
          />
          <Drawer
            className="DashboardLayout-drawer"
            open={isSidebarOpen}
            sx={{
              width: sidemenu.width,
              '& .MuiDrawer-paper': {
                width: sidemenu.width,
                boxSizing: 'border-box',
                backgroundColor: sidemenu.bgColor,
                color: sidemenu.fgColor,
              },
            }}
            variant={smOrDown ? 'temporary' : 'persistent'}
            anchor="left"
            onClose={() => sidebarStore.setOpen(false)}
          >
            <Sidebar />
          </Drawer>

          <Main contentShift={contentShift} className="DashboardLayout-bodyContainer">
            <DrawerHeader />
            {breadcrumbProps && <CustomBreadcrumbs {...breadcrumbProps} />}
            <Box
              className="DashboardLayout-body"
              ref={setBodyElement}
              sx={{
                mt: breadcrumbProps ? 2 : 0,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                pl: 2,
                pr: 4,
                pt: 2,
                width: '100%',
                minHeight: `calc(100vh - ${bodyPosition.y}px)`,
                maxWidth: `calc(100vw - ${bodyPosition.x}px)`,
              }}
              style={{ paddingBottom: contentPaddingBottom }}
            >
              {children}
            </Box>

            {/* {children && <Box sx={{ flex: 1, pt: 2, px: 2 }}>{children}</Box>} */}
            {loading && (
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 30 }}>
                <LinearProgress color="secondary" />
              </div>
            )}
          </Main>
        </DashboardLayoutContext.Provider>
      </Box>
    </EnsureLogin>
  )
}

export default observer(DashboardLayout)
