import { routerPush } from '@/lib/urls'
import log from '@/log'
import { useApi } from '@/provider'
import { ApiRequestContext } from '@aimk/domain'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import { exhaustMap, timer, from } from 'rxjs'

function cancel(ctx?: ApiRequestContext) {
  if (!ctx || ctx.canceled) return
  ctx.canceled = true
  ctx.cancel?.()
}

export default function PwMustResetChecker() {
  const api = useApi()
  const [mustReset, setMustReset] = useState(false)
  const ctxRef = useRef<ApiRequestContext>()
  const doCheck = useCallback(
    async (ctx: ApiRequestContext): Promise<boolean | undefined> => {
      cancel(ctxRef.current)
      ctxRef.current = ctx
      try {
        const { pwMustReset } = await api.admin.checkPwMustReset({ ctx })
        return pwMustReset
      } catch (err) {
        log.debug('ignore error:', err)
        return undefined
      }
    },
    [api],
  )

  useEffect(() => {
    if (mustReset) return
    const s1 = timer(0, 3 * 60 * 1000)
      .pipe(
        exhaustMap(() => {
          const ctx = { canceled: false } as ApiRequestContext
          return from(doCheck(ctx))
        }),
      )
      .subscribe((mustReset) => {
        if (typeof mustReset === 'boolean') {
          setMustReset(mustReset)
        }
      })
    return () => {
      s1.unsubscribe()
    }
  }, [doCheck, mustReset])

  useEffect(() => {
    if (!mustReset) return
    routerPush('/pw-must-reset')
  }, [mustReset])

  useUnmount(() => {
    cancel(ctxRef.current)
  })

  return null
}
