import { routerUrlOf } from '@/lib/urls'
import { flatSx, Link } from '@local/ui'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, IconButton, SxProps, Typography } from '@mui/material'
import clsx from 'clsx'
import { rootSx } from './style'

export type LinkPart = {
  href: string
  title: string
}

export type CustomBreadcrumbsProps = {
  className?: string
  sx?: SxProps
  section?: string
  linkParts?: LinkPart[]
  currentTitle: string
}

export default function CustomBreadcrumbs(props: CustomBreadcrumbsProps) {
  const { className, sx, section, linkParts = [], currentTitle } = props

  return (
    <Box className={clsx('CustomBreadcrumbs-root', className)} sx={flatSx(rootSx, sx)}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <IconButton href={routerUrlOf('/')} color="default" size="medium">
          <HomeOutlinedIcon />
        </IconButton>
        {section && <Typography color="inherit">{section}</Typography>}

        {linkParts.map(({ href, title }, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Link href={href} key={idx} color="inherit">
            {title}
          </Link>
        ))}

        <Typography color="textPrimary">{currentTitle}</Typography>
      </Breadcrumbs>
    </Box>
  )
}
