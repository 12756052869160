import { mainLayoutConstants } from '@/layout-constants'
import { SxProps } from '@mui/material'
import { alpha } from '@mui/material/styles'

const { sidemenu } = mainLayoutConstants

export const rootSx: SxProps = {
  backgroundColor: sidemenu.bgColor,
  color: sidemenu.fgColor,
  fontSize: sidemenu.fontSize,

  // 레이블은 현재 미사용중이라서
  // 추가한 경우 스타일 조정필요합니다
  '& .Sidebar-label': {
    color: alpha(sidemenu.fgColor, 0.7),
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.2,
    pt: 3,
    pb: 1,
  },

  // IMenu의 스타일은 ./components/SectionMenu.tsx 에서 설정
  // ISection의 스타일은 ./components/SideMenuItem.tsx 에서 설정하세요.
}
