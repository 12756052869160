import { mainLayoutConstants } from '@/layout-constants'
import { routerPush, routerUrlOf } from '@/lib/urls'
import useStore from '@/store/useStore'
import { Link, nav } from '@local/ui'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { sideMenuManager } from '../side-menu-list'
import SideMenuItem from './components/SideMenuItem'
import SectionMenu from './components/SideSectionMenu'
import { rootSx } from './style'

const { appbarHeight, sidemenu } = mainLayoutConstants

function Sidebar() {
  const theme = useTheme()
  const { pathname: pathkey } = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { sidebarStore } = useStore()

  const handleClickMenu = (menu: nav.IMenu) => (e: React.MouseEvent) => {
    e.preventDefault()
    if (isMobile) {
      sidebarStore.setOpen(false)
    }
    const { href, sectionId } = menu
    if (sectionId) {
      sidebarStore.expandSection(sectionId)
    }
    routerPush(href)
  }

  // 섹션 클릭
  const handleClickSection = (section: nav.ISection) => (_e: React.MouseEvent) => {
    sidebarStore.toggleExpandSection(section.sectionId)
  }

  return (
    <Box sx={rootSx} className="Sidebar-root">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          height: appbarHeight,
          width: '100%',
          pl: 2,
          pr: 1,
        }}
      >
        <Link
          href="/"
          sx={{
            flex: 1,
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: sidemenu.fgColor,
            '&:hover': {
              color: sidemenu.fgColorActive,
            },
            '&:hover .MuiSvgIcon-root': {
              color: sidemenu.fgColorActive,
            },
          }}
        >
          <img src={routerUrlOf('/images/logo.svg')} alt="" width={35} height={35} />
          <Typography variant="h4" component="div" sx={{ ml: 1 }}>
            admin
          </Typography>
        </Link>
        <IconButton onClick={() => sidebarStore.toggleOpen()} sx={{ ml: 'auto', color: '#666' }}>
          <ChevronLeftIcon />
        </IconButton>
      </Stack>

      <Divider color={sidemenu.dividerColor} />
      <List disablePadding component="nav">
        {sideMenuManager.menuItems.map((item) => {
          if (item.type === 'menu') {
            return (
              <SideMenuItem
                key={item.id}
                className="Sidebar-sideMenuItem"
                icon={item.icon}
                title={item.title}
                onClick={handleClickMenu(item)}
                active={sideMenuManager.isMenuPathMatched(item, pathkey)}
              />
            )
          }
          if (item.type === 'divider') {
            return <Divider key={item.id} color={sidemenu.dividerColor} />
          }
          if (item.type === 'label') {
            return (
              <ListSubheader key={item.id} className="Sidebar-label">
                {item.title}
              </ListSubheader>
            )
          }
          if (item.type === 'section') {
            const section = item
            return (
              <SectionMenu
                key={item.id}
                section={section}
                currentHref={pathkey}
                expanded={sidebarStore.expandedSectionIds.includes(section.sectionId)}
                onClickMenu={handleClickMenu}
                onClickSection={handleClickSection(section)}
              />
            )
          }
          return <div>{JSON.stringify(item)}</div>
        })}
      </List>
    </Box>
  )
}

export default observer(Sidebar)
