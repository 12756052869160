import { mainLayoutConstants } from '@/layout-constants'
import { nav } from '@local/ui'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { alpha } from '@mui/material/styles'
import clsx from 'clsx'
import { sideMenuManager } from '../../side-menu-list'
import SideMenuItem from './SideMenuItem'

const { sidemenu } = mainLayoutConstants

type Props = {
  section: nav.ISection
  expanded: boolean
  currentHref?: string
  onClickSection: (e: React.MouseEvent) => void
  onClickMenu: (menu: nav.IMenu) => (e: React.MouseEvent) => void
}

export default function SideSectionMenu(props: Props) {
  const { section, expanded = false, currentHref, onClickMenu, onClickSection } = props

  return (
    <>
      <ListItemButton
        onClick={onClickSection} //
        className={clsx('SideSectionMenu-root', {
          'SideSectionMenu-expanded': expanded, // 현재 섹션이 펼쳐진 상태
          'SideSectionMenu-active': sideMenuManager.isSectionPathMatched(section, currentHref), // 섹션안에 현재 메뉴가 존재
        })}
        sx={[
          {
            color: sidemenu.fgColor,
            pr: 0,
            '&:hover': {
              backgroundColor: sidemenu.bgColorBack,
            },

            // 섹션 메뉴 제목
            '& .SideSectionMenu-title': {
              fontWeight: 400,
              fontSize: sidemenu.fontSize,
              color: sidemenu.fgColor,
            },

            // 섹션 아이콘, expand 아이콘
            '& .MuiListItemIcon-root': {
              color: alpha(sidemenu.fgColor, 0.7),
              width: sidemenu.iconWidth,
              minWidth: sidemenu.iconWidth,
            },

            // 섹션 펼쳐진 상태
            '&.SideSectionMenu-expanded, &.SideSectionMenu-active': {
              color: sidemenu.fgColorActive,
              '& .SideSectionMenu-title': {
                // color: alpha(sidemenu.fgColorActive, 0.8),
              },
              '& .MuiListItemIcon-root': {
                color: sidemenu.fgColorActive,
              },
            },
          },
        ]}
      >
        {section.icon && (
          <ListItemIcon className="SideSectionMenu-sectionIcon">{section.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={section.title}
          classes={{ primary: 'title' }}
          className="SideSectionMenu-title"
        />
        <ListItemIcon className="SideSectionMenu-expandIndicator">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {section.submenus.map((menu) => {
            if (menu.type === 'divider') {
              return <Divider key={menu.id} />
            }
            return (
              <SideMenuItem
                key={menu.id}
                icon={menu.icon}
                title={menu.title}
                sx={{ pl: 6 }}
                active={sideMenuManager.isMenuPathMatched(menu, currentHref)}
                onClick={onClickMenu(menu)}
              />
            )
          })}
        </List>
      </Collapse>
    </>
  )
}
